import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import AppleIcon from '@/assets/png/apple.png';
import FbIcon from '@/assets/png/fb.png';
import GoogleIcon from '@/assets/png/google.png';
import StarIcon from '@/assets/png/Star.png';
import UnionIcon from '@/assets/png/Union.png';
import LoadingSpinner from '@/components/LoadingSpinner';
import Button from '@/components/ui/button';
import { TextInputCombo } from '@/components/ui/Input';
import { ROUTES, UserRoles } from '@/constants';
import { useAppDispatch } from '@/hooks/redux';
import { setUser } from '@/redux/slices/authSlice';
import { signinUser } from '@/services/authServices';
import { setTokenToLS } from '@/utils/common';

const LoginForm = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t('login.errors.invalidEmail'))
      .required(t('login.errors.emailRequired')),
    password: Yup.string().required(t('login.errors.passwordRequired')),
  });

  const INITIAL_VALUES = {
    email: '',
    password: '',
  };

  const handleSubmit = async (values: typeof INITIAL_VALUES) => {
    setIsLoading(true);
    const response = await signinUser(values);
    if (response) {
      const {
        token,
        data: { userRole, hasBusiness },
      } = response.data;

      const isUserPlanner = userRole === UserRoles.PLANNER;

      const user = response.data.data;

      dispatch(setUser({ user, token }));
      localStorage?.setItem('user', JSON.stringify(user));
      setTokenToLS(token);
      if (isUserPlanner) {
        navigate(ROUTES.PLANNER_DASHBOARD);
      } else {
        if (hasBusiness) {
          navigate(ROUTES.VENDOR_DASHBOARD);
        } else {
          navigate(ROUTES.VENDOR_REGISTER);
        }
      }
    }
    setIsLoading(false);
  };

  return (
    <div className='flex items-center w-full justify-center md:flex-col'>
      <img
        src={UnionIcon}
        width={31}
        height={26}
        alt='Union Icon'
        className='absolute left-10 top-24 md:block hidden'
      />

      <div className='flex items-center w-10/12 md:w-auto justify-center self-center md:flex-col mt-5 sm:mt-14'>
        <div className='mt-1.5 flex flex-col items-center self-center w-full'>
          {isLoading && <LoadingSpinner />}
          <div className='flex flex-col items-center gap-3.5 w-full'>
            <h1 className='sm:text-4xl text-2xl font-semibold text-headingColor font-sans'>
              {t('login.heading')}
            </h1>
            <img
              width={28}
              height={28}
              src={StarIcon}
              alt='Star Icon'
              className='absolute md:right-[400px] md:block hidden top-40'
            />

            <div className='flex justify-center gap-1 mb-7'>
              <p className='text-[16px] font-normal text-paragraphText font-sans'>
                {t('login.notAMemberTxt')}
              </p>
              <Link
                to={ROUTES.REGISTER}
                className='text-[16px] font-semibold text-brandGreen font-sans'
              >
                {t('login.joinNowTxt')}
              </Link>
            </div>
          </div>

          <div className='flex flex-col gap-2.5 w-full'>
            <Button
              variant='outline'
              size='medium'
              onClick={() => {}}
              iconLeft={<img src={FbIcon} alt='Facebook Icon' />}
            >
              {t('login.signInWithFb')}
            </Button>
            <Button
              variant='outline'
              size='medium'
              onClick={() => {}}
              iconLeft={<img src={GoogleIcon} alt='Google Icon' />}
            >
              {t('login.signInWithGoogle')}
            </Button>

            <Button
              variant='outline'
              size='medium'
              onClick={() => {}}
              iconLeft={<img src={AppleIcon} alt='Apple Icon' />}
            >
              {t('login.signInWithApple')}
            </Button>
          </div>
          <p className='font-sans text-base font-medium text-inputTxtColor text-center my-6'>
            {t('login.loginWithEmail')}
          </p>

          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className='flex flex-col gap-2.5 w-full'>
                <TextInputCombo
                  name='email'
                  placeholder={t('login.emailPlaceholder')}
                  type='email'
                  label={t('login.emailLabel')}
                />
                <TextInputCombo
                  name='password'
                  type='password'
                  placeholder={t('login.passwordPlaceholder')}
                  label={t('login.passwordLabel')}
                />

                <div className='w-full text-end mb-6'>
                  <Link
                    to={ROUTES.FORGOT_PASSWORD}
                    className='text-brandGreen text-[14px] font-medium font-sans my-2'
                  >
                    {t('login.forgotPasswordBtn')}
                  </Link>
                </div>
                <Button
                  variant='filled'
                  size='medium'
                  type='submit'
                  disabled={isSubmitting}
                  className='text-white bg-brandGreen w-full'
                >
                  {isSubmitting
                    ? t('login.signInLoadingBtn')
                    : t('login.signInBtn')}
                </Button>
              </Form>
            )}
          </Formik>

          <p className='font-sans text-base font-medium text-textGray text-center my-4'>
            {t('login.areYouAVendorTxt')}
          </p>

          <Link
            to={ROUTES.VENDOR_REGISTER}
            className='text-textDarkGray text-[16px] font-medium font-sans'
          >
            {t('login.joinAsVendorBtn')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
