/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Slider from 'rc-slider';

import Map from '@/assets/png/Map.png';
import DropdownArrow from '@/assets/svg/DropdownArrow';
import Filter from '@/assets/svg/Filter';
import Selected from '@/assets/svg/Selected';
import { useAppSelector } from '@/hooks/redux';
import { setVenueNameSearch } from '@/redux/slices/filters';
import { FilterSectionProps } from '@/utils/types/storeStateInterface';

import SearchInput from './SearchInput';
import { Typography } from './Typography';

import 'rc-slider/assets/index.css';

const FilterSection: React.FC<FilterSectionProps> = ({
  title,
  items,
  type = 'tile',
  expand,
  multiSelect = false,
  setFilters,
  name = '',
  filters,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleSelect = (itemLabel: string) => {
    if (setFilters) {
      if (multiSelect) {
        const prevItems = filters?.[name] || [];
        const filterData = prevItems?.includes(itemLabel)
          ? prevItems.filter((label: string) => label !== itemLabel)
          : [...prevItems, itemLabel];

        setFilters({
          ...filters,
          [name]: filterData,
        });
      } else {
        setFilters({
          ...filters,
          [name]: filters?.[name]?.includes(itemLabel) ? '' : [itemLabel],
        });
      }
    }
  };

  const renderFilters = () => {
    if (type === 'label') {
      return (
        <div className='bg-[#F8F8F8] flex justify-between items-center lg:text-sm text-xs mb-3 py-[12px] px-3 rounded-[10px] mt-2 text-paragraphText cursor-pointer'>
          <label>{title}</label>
          <DropdownArrow className={isOpen ? 'rotate-90' : '-rotate-90'} />
        </div>
      );
    } else if (type === 'favourite') {
      return (
        <div
          className='bg-[#F8F8F8] flex justify-between items-center lg:text-sm text-xs mb-3 py-[12px] px-3 rounded-[10px] mt-2 text-black border cursor-pointer'
          style={{
            backgroundColor: filters?.[name].includes('true')
              ? '#EEEEEE'
              : '#ffffff',
          }}
          onClick={() => {
            if (filters?.[name].includes('true')) {
              handleSelect('false');
            } else {
              handleSelect('true');
            }
          }}
        >
          <label>{title}</label>
        </div>
      );
    } else if (type === 'check') {
      return (
        <div className='border flex lg:text-sm text-xs justify-between items-center mb-3 py-[12px] px-3 rounded-[10px] mt-2 text-paragraphText'>
          <label>{title}</label>
          <input
            className='cursor-pointer'
            type='checkbox'
            width={50}
            height={50}
            onChange={() => {
              if (setFilters) {
                setFilters({
                  ...filters,
                  [name]:
                    filters?.[name] === '' || filters?.[name] === 'false'
                      ? 'true'
                      : 'false',
                });
              }
            }}
            checked={filters?.[name] === 'true'}
          />
        </div>
      );
    }

    return (
      <div className='mb-3 border rounded-[10px] px-4 py-[13px]'>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`w-full text-left lg:text-base text-sm font-semibold text-gray-700 ${items?.length && isOpen ? 'pb-2' : ''} flex justify-between items-center`}
        >
          {title}
          {items?.length || expand ? (
            <DropdownArrow className={!expand && isOpen ? '' : 'rotate-180'} />
          ) : null}
        </button>
        {isOpen && items?.length ? (
          <div
            className={`mt-2 ${type === 'chip' ? 'flex gap-2 flex-wrap' : ''}`}
          >
            {items.map((item, idx) => (
              <label
                key={idx}
                onClick={() => handleSelect(item.value)}
                className={`py-[10px] px-3 lg:text-sm text-xs leading-4 rounded-[10px] flex justify-between items-center ${type === 'chip' ? '' : 'mt-2'} ${title === 'Color Themes' ? 'border border-lightGray' : ''} text-paragraphText cursor-pointer`}
                style={{
                  backgroundColor: filters?.[name]?.includes(item.value)
                    ? title === 'Color Themes'
                      ? item.selectedColor
                      : '#EEEEEE'
                    : title === 'Color Themes'
                      ? item.color
                      : '#F8F8F8',
                  color:
                    title === 'Color Themes'
                      ? filters?.[name]?.includes(item.value) &&
                        item?.label !== 'White'
                        ? 'white'
                        : item.textColor
                      : '#737780',
                  border:
                    filters?.[name]?.includes(item.value) &&
                    item?.label === 'White'
                      ? '1px solid black'
                      : '',
                }}
              >
                {item.label}
                {filters?.[name]?.includes(item.value) &&
                title !== 'Color Themes' &&
                type !== 'chip' ? (
                  <Selected width={16} height={16} color='#d1d1d1' />
                ) : null}
              </label>
            ))}
          </div>
        ) : null}
      </div>
    );
  };

  return renderFilters();
};

interface FiltersProps {
  sections: FilterSectionProps[];
  search?: boolean;
  distance?: boolean;
  map?: boolean;
  disabled?: boolean;
  setFilters?: any;
  filters?: any;
  searchValue?: string;
}

const Filters: React.FC<FiltersProps> = ({
  sections,
  search = false,
  distance = false,
  map = true,
  setFilters,
  filters,
}) => {
  const dispatch = useDispatch();
  const { venueNameSearch } = useAppSelector((state) => state.filters);

  const [capacityRange, setCapacityRange] = useState([0, 1000]);
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const [prevData, setPrevData] = useState<any>({
    minCapacity: 0,
    maxCapacity: 1000,
    minRate: 0,
    maxRate: 10000,
  });
  const [isOpen, setIsOpen] = useState(true);
  const [distanceValue, setDistanceValue] = useState<string | undefined>();

  const [debouncedCapacityRange, setDebouncedCapacityRange] =
    useState(capacityRange);
  const [debouncedPriceRange, setDebouncedPriceRange] = useState(priceRange);

  const handleSliderChange = (values: any) => {
    setCapacityRange(values);
  };
  const handlePriceSliderChange = (values: any) => {
    setPriceRange(values);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedCapacityRange(capacityRange);
    }, 1000);

    return () => clearTimeout(timer);
  }, [capacityRange]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedPriceRange(priceRange);
    }, 1000);

    return () => clearTimeout(timer);
  }, [priceRange]);

  useEffect(() => {
    if (
      capacityRange[0] !== prevData.minCapacity ||
      capacityRange[1] !== prevData.maxCapacity
    ) {
      setFilters({
        ...filters,
        minCapacity: debouncedCapacityRange[0],
        maxCapacity: debouncedCapacityRange[1],
      });
      setPrevData({
        ...prevData,
        minCapacity: debouncedCapacityRange[0],
        maxCapacity: debouncedCapacityRange[1],
      });
    } else if (
      debouncedPriceRange[0] !== prevData.minRate ||
      debouncedPriceRange[1] !== prevData.maxRate
    ) {
      setFilters({
        ...filters,
        minRate: debouncedPriceRange[0],
        maxRate: debouncedPriceRange[1],
      });
      setPrevData({
        ...prevData,
        minRate: debouncedPriceRange[0],
        maxRate: debouncedPriceRange[1],
      });
    }
  }, [debouncedCapacityRange, debouncedPriceRange]);

  return (
    <div className='w-full'>
      {map ? (
        <div className='mb-3 bg-[#EEEEEE] rounded-[10px] relative p-2'>
          <img src={Map} alt='' className='w-full' />
          <button className='p-3 rounded-[10px] bg-white absolute bottom-1 lg:bottom-2 w-[90%] lg:w-[96%]'>
            View in a map
          </button>
        </div>
      ) : null}
      {distance ? (
        <div className='mb-3 border rounded-[10px] px-4 py-[13px]'>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={`w-full text-left font-semibold text-gray-700 ${isOpen ? 'pb-2' : ''} flex justify-between items-center`}
          >
            Distance
            <DropdownArrow className={isOpen ? '' : 'rotate-180'} />
          </button>
          <SearchInput placeHolder='Address or zip Code' />
          {isOpen ? (
            <div className={'mt-2'}>
              {[
                'Within 5 miles',
                'Within 10 miles',
                'Within 25 miles',
                'Within 50 miles',
                'Within 100 miles',
              ].map((item, idx) => (
                <label
                  key={idx}
                  className={
                    'py-[10px] flex gap-2 items-center border border-borderColor mt-2 px-3 text-xs lg:text-sm leading-4 rounded-[10px] text-paragraphText cursor-pointer'
                  }
                >
                  <input
                    type='radio'
                    name='distance'
                    onChange={() => setDistanceValue(item)}
                    checked={distanceValue === item}
                  />
                  <p>{item}</p>
                </label>
              ))}
            </div>
          ) : null}
        </div>
      ) : null}

      <h3
        className={`font-medium flex items-center gap-2 p-3 rounded-t-lg bg-[#EEEEEE] text-[#3D424D] md: ${search ? '' : 'mb-3'} `}
      >
        <Filter /> Filters
      </h3>

      {search ? (
        <div className='border border-t-0 p-3  lg:p-5'>
          <Typography className='lg:text-sm !text-xs'>
            Search by venu name
          </Typography>
          <SearchInput
            background='white'
            value={venueNameSearch}
            setValue={(e: { target: { value: string } }) =>
              dispatch(setVenueNameSearch(e.target.value))
            }
          />
        </div>
      ) : null}
      {search ? (
        <div className='relative border border-t-0 p-5 mb-4 rounded-b-lg'>
          <label className='block text-gray-700 font-medium mb-2'>
            Capacity
          </label>
          <div className='flex items-center justify-between mb-2 lg:text-sm text-xs'>
            <span className='text-gray-500'>ii</span>
            <span className='text-gray-500'>10</span>
            <span className='text-gray-500'>100</span>
            <span className='text-gray-500'>1k</span>
            <span className='text-gray-500'>iiii</span>
          </div>

          <Slider
            range
            min={0}
            max={1000}
            value={capacityRange}
            onChange={handleSliderChange}
            className='w-full'
            trackStyle={[{ backgroundColor: '#d9d9d9' }]}
            handleStyle={[
              { borderColor: '#515151' },
              { borderColor: '#515151' },
            ]}
          />

          <div className='flex xl:flex-row flex-col lg:text-sm text-xs justify-center gap-5 text-gray-500 mt-2'>
            <span className='border flex gap-1 items-center rounded-[10px] border-[#D2D3D6] py-[9px] px-4 xl:w-28'>
              Min:{' '}
              <input
                type='number'
                min='0'
                value={capacityRange[0]}
                onChange={(e) =>
                  handleSliderChange([Number(e.target.value), capacityRange[1]])
                }
                disabled
                className='focus:outline-none bg-transparent'
              />
            </span>
            <span className='border flex gap-1 items-center rounded-[10px] border-[#D2D3D6] py-[9px] px-4 xl:w-28'>
              Max:
              <input
                type='number'
                max='1000'
                value={capacityRange[1]}
                onChange={(e) =>
                  handleSliderChange([capacityRange[0], Number(e.target.value)])
                }
                disabled
                className='focus:outline-none bg-transparent'
              />
            </span>
          </div>
        </div>
      ) : null}
      {search ? (
        <div className='relative border p-5 mb-4 rounded-lg'>
          <label className='block text-gray-700 font-medium mb-2'>Price</label>
          <div className='flex items-center lg:text-sm text-xs justify-between mb-2'>
            <span className='text-gray-500'>ii</span>
            <span className='text-gray-500'>10</span>
            <span className='text-gray-500'>100</span>
            <span className='text-gray-500'>1k</span>
            <span className='text-gray-500'>iiii</span>
          </div>

          <Slider
            range
            min={0}
            max={10000}
            value={priceRange}
            onChange={handlePriceSliderChange}
            className='w-full'
            trackStyle={[{ backgroundColor: '#d9d9d9' }]}
            handleStyle={[
              { borderColor: '#515151' },
              { borderColor: '#515151' },
            ]}
          />

          <div className='flex xl:flex-row lg:text-sm text-xs flex-col justify-center gap-5 text-gray-500 mt-2'>
            <span className='border flex gap-1 items-center rounded-[10px] border-[#D2D3D6] py-[9px] px-4 xl:w-28'>
              Min:{' '}
              <input
                type='number'
                min='0'
                value={priceRange[0]}
                onChange={(e) =>
                  handlePriceSliderChange([
                    Number(e.target.value),
                    priceRange[1],
                  ])
                }
                disabled
                className='focus:outline-none bg-transparent'
              />
            </span>
            <span className='border flex gap-1 items-center rounded-[10px] border-[#D2D3D6] py-[9px] px-4 xl:w-28'>
              Max:
              <input
                type='number'
                max='1000'
                value={priceRange[1]}
                onChange={(e) =>
                  handlePriceSliderChange([
                    priceRange[0],
                    Number(e.target.value),
                  ])
                }
                disabled
                className='focus:outline-none bg-transparent'
              />
            </span>
          </div>
        </div>
      ) : null}
      {sections.map((section, index) => (
        <FilterSection
          key={index}
          title={section.title}
          items={section.items}
          type={section.type}
          expand={section.expand}
          multiSelect={section.multiSelect}
          name={section.name}
          setFilters={setFilters}
          filters={filters}
        />
      ))}
    </div>
  );
};

export default Filters;
