/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import DropdownArrow from '@/assets/svg/DropdownArrow';
import Filter from '@/assets/svg/Filter';
import Loader from '@/assets/svg/loader';
import Filters from '@/components/FiltersSection';
import { FilterSectionProps, ICard } from '@/utils/types/storeStateInterface';

import Container from './ui/Container';
import Card from './Card';
import Footer from './Footer';
import Modal from './Modal';
import PlannerHeader from './PlannerHeader';
import PlannerHero from './PlannerHero';
import PlannerInterestedSection from './PlannerInterestedSection';
import Tabs from './Tabs';
import { Typography } from './Typography';

const PlannerLayout = ({
  sections,
  cardData,
  tabLength,
  heroLength,
  heroRoute,
  skipText,
  cardType,
  search,
  distance,
  setFilters,
  filters,
  totalTablePages,
  setPage,
  apiRoute,
  multiSelect,
  map,
  loading,
  setPartFavour,
  partyFavour,
}: {
  sections: FilterSectionProps[];
  cardData: ICard[];
  tabLength: number;
  heroLength: number;
  skipText?: string;
  heroRoute: string;
  cardType?: string;
  search?: boolean;
  distance?: boolean;
  disabled?: boolean;
  setFilters?: any;
  filters?: any;
  totalTablePages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  apiRoute: string;
  multiSelect?: boolean;
  map?: boolean;
  loading: boolean;
  setPartFavour?: React.Dispatch<React.SetStateAction<string[]>>;
  partyFavour?: string[];
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>(
    multiSelect ? [] : '',
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('eventId');
  const totalPages = totalTablePages;

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
      setPage((prev) => prev + 1);
    }
  };
  const handleSelect = (id: string) => {
    if (multiSelect) {
      if (!selectedValue?.includes(id)) {
        if (
          filters?.partyFavors?.includes('true') &&
          setPartFavour &&
          partyFavour
        ) {
          if (partyFavour?.includes(id)) {
            const partyFilterData = partyFavour.filter(
              (value: string) => value !== id,
            );
            setPartFavour(partyFilterData);
          } else {
            setPartFavour([...partyFavour, id]);
          }
          return;
        }
        setSelectedValue([...selectedValue, id]);
      } else {
        const filterData = selectedValue.filter(
          (value: string) => value !== id,
        );
        setSelectedValue(filterData);
      }
    } else {
      if (selectedValue === id) {
        setSelectedValue('');
      } else {
        setSelectedValue(id);
      }
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      setPage((prev) => prev - 1);
    }
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
    setPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      // Show first two pages, last two pages, and current page with neighbors
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - 1 && i <= currentPage + 1)
      ) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            className={`px-4 py-2 rounded-md ${
              currentPage === i ? 'bg-[#F0D29C] font-semibold' : 'border'
            }`}
          >
            {i}
          </button>,
        );
      } else if (
        (i === currentPage - 2 && currentPage > 3) ||
        (i === currentPage + 2 && currentPage < totalPages - 2)
      ) {
        pageNumbers.push(
          <span key={i} className='px-2'>
            ...
          </span>,
        );
      }
    }
    return pageNumbers;
  };

  return (
    <div className='w-full'>
      <PlannerHeader />
      <div className='md:mb-20 mb-10'>
        <PlannerHero
          length={heroLength}
          route={heroRoute}
          apiRoute={apiRoute}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          partyFavour={partyFavour}
          setPartyFavour={setPartFavour}
        />
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Filters
            sections={sections}
            search={search}
            distance={distance}
            setFilters={setFilters}
            filters={filters}
          />
        </Modal>

        <Container className='md:w-[95%] lg:w-4/5'>
          <Tabs length={tabLength} />{' '}
          <button
            onClick={() => setIsModalOpen(true)}
            className={
              'font-medium flex items-center text-sm gap-5 mb-2 p-3 rounded-lg bg-[#EEEEEE] text-[#3D424D] md:hidden '
            }
          >
            <Filter /> Filters{' '}
            <div className='p-1 rounded-sm bg-[#F3F3F5]'>
              <DropdownArrow
                width={15}
                height={15}
                // className={isOpen ? '' : 'rotate-180'}
              />
            </div>
          </button>
          {skipText ? (
            <Typography
              variant='subheading'
              className='text-center font-medium text-textDarkGray md:text-lg text-sm mb-4 block'
            >
              {skipText}{' '}
              <span
                className='cursor-pointer'
                onClick={() => {
                  navigate(`${heroRoute}?eventId=${id}`);
                }}
              >
                {' '}
                Skip this step
              </span>
            </Typography>
          ) : null}
          <div className='flex w-full gap-5 rounded-2xl'>
            <div className='w-1/4 hidden md:block'>
              <Filters
                sections={sections}
                search={search}
                distance={distance}
                setFilters={setFilters}
                filters={filters}
                map={map}
              />
            </div>
            <div className='md:w-3/4'>
              <div className='bg-light-bg md:h-[166vh] overflow-auto px-3 md:px-6 md:py-3 md:w-full w-[90vw] rounded-xl'>
                {loading ? (
                  <div className='flex justify-center h-full items-center'>
                    <Loader />
                  </div>
                ) : cardData?.length ? (
                  cardData?.map((card, idx) => (
                    <Card
                      key={idx}
                      title={card.title}
                      imgUrl={card.imgUrl}
                      tags={card.tags}
                      isTheme={apiRoute === 'theme' ? true : false}
                      type={cardType}
                      cardData={card.cardData}
                      socialLinks={card.socialLinks}
                      isFavorite={card.isFavorite}
                      isSelected={
                        multiSelect
                          ? filters?.partyFavors?.includes('true')
                            ? partyFavour?.includes(card?._id)
                            : selectedValue?.includes(card?._id)
                          : selectedValue === card?._id
                      }
                      _id={card?._id}
                      handleSelect={handleSelect}
                    />
                  ))
                ) : (
                  <div className='flex items-center justify-center h-full my-2'>
                    <Typography
                      variant='subheading'
                      className='md:text-lg text-sm'
                    >
                      No Data Found
                    </Typography>
                  </div>
                )}
              </div>
              <div className='flex justify-between md:w-3/4 mx-auto items-center mt-10'>
                <button
                  onClick={handlePrevious}
                  disabled={currentPage === 1}
                  className='px-4 py-2 border rounded-md disabled:opacity-50 text-sm md:text-base'
                >
                  &lt; Back
                </button>
                <div className='flex gap-3 text-sm md:text-base'>
                  {renderPageNumbers()}
                </div>
                <button
                  onClick={handleNext}
                  disabled={currentPage === totalPages}
                  className='px-4 py-2 border rounded-md disabled:opacity-50 text-sm md:text-base'
                >
                  Next &gt;
                </button>
              </div>
            </div>
          </div>
          <PlannerInterestedSection />
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default PlannerLayout;
