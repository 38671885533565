import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { ROUTES } from '@/constants';
import { JSONParse } from '@/utils/jsonparse';

import Dashboard from './modules/Dashboard';

const VendorDashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const data = localStorage?.getItem('user');
    const user: any = data ? JSONParse(data) : null;

    if (user?.userRole === 'planner') {
      navigate(ROUTES.PLANNER_DASHBOARD);
    }
  }, []);

  return (
    <div className='w-full'>
      <Header />
      <Dashboard />
      <Footer />
    </div>
  );
};

export default VendorDashboard;
