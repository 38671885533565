/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  themeFilters: {
    ageRange: [],
    environment: [],
    season: [],
    colorTheme: [],
    favorites: ['false'],
  },
  venueFilters: {
    wirelessInternet: '',
    outsideCatering: '',
    smokingAllowed: '',
    venueType: '',
    minCapacity: '',
    maxCapacity: '',
    minRate: '',
    maxRate: '',
    favorites: ['false'],
  },
  decorFilters: {
    decorTypes: '',
    rentalOptions: '',
    decorPieces: '',
    partyFavors: '',
    favorites: ['false'],
  },
  foodFilters: {
    decorcateringAmenitiesTypes: [],
    servingStyles: [],
    dietaryOptions: [],
    cuisine: [],
    pickUp: '',
    delivery: '',
    favorites: ['false'],
  },
  desertFilters: {
    priceLevel: '',
    pickUp: '',
    delivery: '',
    desserts: [],
    bakeryDietaryOptions: [],
    bakeryCuisine: [],
    favorites: ['false'],
  },
  barFilters: {
    priceLevel: '',
    provideAlcohol: '',
    customerBuyAlcohol: '',
    drinkTypesOffered: '',
    drinkServices: '',
    favorites: ['false'],
  },
  photographerFilters: {
    priceLevel: '',
    type: [],
    additionalServices: [],
    favorites: ['false'],
  },
  entertainmentFilters: {
    entertainmentType: [],
    musicianOptions: [],
    entertainmentHourlyRateLow: '',
    entertainmentHourlyRateHigh: '',
    favorites: ['false'],
  },
  partyFilters: {
    priceLevel: '',
    favorites: ['false'],
  },
  venueNameSearch: '',
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setThemeFilters: (state, action: PayloadAction<any>) => {
      state.themeFilters = action.payload;
    },
    setVenueFilters: (state, action: PayloadAction<any>) => {
      state.venueFilters = action.payload;
    },
    setDecorFilters: (state, action: PayloadAction<any>) => {
      state.decorFilters = action.payload;
    },
    setFoodFilters: (state, action: PayloadAction<any>) => {
      state.foodFilters = action.payload;
    },
    setDesertFilters: (state, action: PayloadAction<any>) => {
      state.desertFilters = action.payload;
    },
    setBarFilters: (state, action: PayloadAction<any>) => {
      state.barFilters = action.payload;
    },
    setPhotographerFilters: (state, action: PayloadAction<any>) => {
      state.photographerFilters = action.payload;
    },
    setEntertainmentFilters: (state, action: PayloadAction<any>) => {
      state.entertainmentFilters = action.payload;
    },
    setPartyFilters: (state, action: PayloadAction<any>) => {
      state.partyFilters = action.payload;
    },
    setVenueNameSearch: (state, action: PayloadAction<any>) => {
      state.venueNameSearch = action.payload;
    },
  },
  extraReducers: () => {},
});

export const {
  setThemeFilters,
  setVenueFilters,
  setDecorFilters,
  setFoodFilters,
  setDesertFilters,
  setBarFilters,
  setPhotographerFilters,
  setPartyFilters,
  setEntertainmentFilters,
  setVenueNameSearch,
} = filtersSlice.actions;
