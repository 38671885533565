import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Footer from '@/components/Footer';
import PlannerHeader from '@/components/PlannerHeader';
import { ROUTES } from '@/constants';
import { JSONParse } from '@/utils/jsonparse';

import PlannerSection from './modules/PlannerSection';

const PlannerDashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const data = localStorage?.getItem('user');
    const user: any = data ? JSONParse(data) : null;

    if (user?.userRole !== 'planner') {
      navigate(ROUTES.VENDOR_DASHBOARD);
    }
  }, []);

  return (
    <div className='w-full'>
      <PlannerHeader />
      <PlannerSection />
      <Footer />
    </div>
  );
};

export default PlannerDashboard;
